<template>
  <div>
    <center>
      <div class="mb-4">
        <img
          src="https://d3oia8etllorh5.cloudfront.net/us-east-1_dM0EI6uHe/ALL/20211208193832/assets/images/image.jpg"
        />
      </div>
      <v-alert icon="mdi-logout" prominent text type="info">
        <v-row align="center" no-gutters>
          <v-col class="grow">
            <h2 class="font-weight-light">Log out successful</h2>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="shrink">
            <v-btn color="white" plain outlined href="init-login">Login</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </center>
  </div>
</template>

<script>
import store from "@/store/index";
export default {
  name: "Logout",
  mounted() {
    store.commit("replaceValue", { key: "authUser", value: false });
    window.localStorage.clear();
  },
  data: function () {
    return {};
  },
};
</script>
